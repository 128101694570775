import classnames from "classnames";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";

import { zonedTimeFormat } from "@inferno/renderer-shared-core";
import "./DateDisplay.style.scss";

interface DateDisplayProps {
  datetime: string | number | undefined;
  dateFormat?: string;
  text?: string;
  language?: string;
  timezone?: string;
  className?: string;
  store?: Store;
}

export const DateDisplay = inject("store")(
  ({ datetime, dateFormat, text, timezone, language, className, store }: DateDisplayProps) => {
    if (datetime && store) {
      const { env } = store;
      const locale = language === "es" ? es : enUS;
      const tz = timezone || env.DEFAULT_TIMEZONE;

      const formatted = zonedTimeFormat({
        date: datetime,
        timezone: tz,
        outputFormat: dateFormat,
        locale,
      });
      const timeClass = classnames("component-date-display", {
        [`${className}`]: className,
      });
      return (
        <time className={timeClass} dateTime={`${datetime}`}>
          {text}
          {formatted}
        </time>
      );
    }
    return null;
  },
);

export default DateDisplay;
