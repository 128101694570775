import loadable from "@loadable/component";
import classnames from "classnames";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import React, { Component, HTMLAttributes } from "react";

import type { AnalyticsEventAction } from "@inferno/renderer-shared-core";
import { canHaveOps, getResponsiveValues, Sizes, SrcSet } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../ui";
import { DateDisplay } from "../DateDisplay.component";
import type { EyebrowProps } from "../Eyebrow.component";
import { ResponsiveImage } from "../ResponsiveImage.component";

import "./ContentTile.style.scss";
import type { Store } from "@inferno/renderer-shared-core";

const Eyebrow = loadable(() => import("../Eyebrow.component"));

export interface ContentTileProps extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {
  title: string;
  url: string;
  slug: string;
  thumb: string;
  target?: string;
  layout?:
    | "thumb-top"
    | "thumb-left"
    | "thumb-left-microsite"
    | "thumb-right"
    | "thumb-right-microsite"
    | "square"
    | "flag-tile";
  eyebrow?: EyebrowProps | null;
  sponsored?: boolean;
  datetime?: string | number;
  dateFormat?: string;
  store?: Store;
  action?: AnalyticsEventAction;
  alt?: string;
  showDateStamp?: boolean;
}

interface ContentTileLinkProps extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {
  url: string;
  target: string;
  context: string;
  action?: AnalyticsEventAction;
  children: JSX.Element | string | null;
  fallback?: string;
}

const TileLink = ({
  url,
  className,
  target,
  context,
  action,
  children,
  fallback,
}: ContentTileLinkProps): JSX.Element | null => {
  if (url) {
    return (
      <MagicLink className={className} to={url} target={target} context={context} action={action}>
        {children}
      </MagicLink>
    );
  }

  if (fallback) {
    return React.createElement(fallback, { className }, children);
  }

  return <>{children}</>;
};

const TileEyebrow = ({ eyebrow, sponsored }: { eyebrow: EyebrowProps | undefined; sponsored: boolean }) => {
  if (sponsored) {
    return <Eyebrow topic_name={i18n.t("sponsored_content")} />;
  } else if (eyebrow) {
    return <Eyebrow {...eyebrow} />;
  }
  return null;
};

@inject("store")
@observer
export class ContentTile extends Component<ContentTileProps> {
  public static defaultProps: ContentTileProps = {
    title: "",
    url: "",
    slug: "",
    target: "",
    thumb: "//i.iheart.com/v2/img/default",
    layout: "thumb-top",
    sponsored: false,
  };
  private imageSrc: string;
  private srcset: SrcSet[];
  private sizes: Sizes[];
  private placeholderSrcset: SrcSet[];
  private dimensions: {
    width: number;
    height: number;
  };

  constructor(props: ContentTileProps) {
    super(props);
    const responsive = getResponsiveValues(this.props.thumb, this.props.layout);
    this.srcset = responsive.srcset;
    this.sizes = responsive.sizes;
    this.dimensions = responsive.dimensions;
    this.placeholderSrcset = responsive.placeholderSrcset;
    this.imageSrc = this.srcset.length > 0 ? this.srcset[0].url : this.props.thumb;
  }

  public render() {
    if (!this.props.store) {
      return null;
    }
    const { site, env } = this.props.store;
    let alt = this.props.title;
    if (this.props.alt) {
      alt = this.props.alt;
    } else if (this.props.eyebrow) {
      alt = `${this.props.eyebrow.topic_name} - ${alt}`;
    }
    const tileClass = classnames(
      ["component-content-tile", this.props.layout, ...(this.props.className || "").split(" ")],
      {
        external: !canHaveOps(this.props.thumb),
      },
    );

    const target = this.props.target || "_self";

    return (
      <figure className={tileClass}>
        <div className="thumb-container">
          <TileLink url={this.props.url} target={target} context={this.props.slug} action={this.props.action}>
            <ResponsiveImage
              alt={alt}
              src={this.imageSrc}
              srcset={this.srcset}
              sizes={this.sizes}
              placeholderSrcset={this.placeholderSrcset}
              initialWidth={this.dimensions.width}
              initialHeight={this.dimensions.height}
            />
          </TileLink>
        </div>
        {this.props.eyebrow || this.props.title || this.props.datetime ? (
          <figcaption>
            {this.props.eyebrow ? (
              <TileEyebrow eyebrow={this.props.eyebrow} sponsored={this.props.sponsored || false} />
            ) : null}
            {this.props.title ? (
              <TileLink
                className="card-title"
                url={this.props.url}
                target={target}
                context={this.props.slug}
                action={this.props.action}
                fallback="span"
              >
                {this.props.title}
              </TileLink>
            ) : null}
            {this.props.showDateStamp && this.props.datetime ? (
              <DateDisplay
                datetime={this.props.datetime}
                dateFormat={this.props.dateFormat}
                timezone={site?.index.timeZone || env.DEFAULT_TIMEZONE}
              />
            ) : null}
          </figcaption>
        ) : null}
      </figure>
    );
  }
}

export default ContentTile;
