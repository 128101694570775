import { placeholderImage } from "@ihr-radioedit/inferno-core";
import utf8 from "utf8";

export const iHeartDefaultImg = "//i.iheart.com/v2/img/default";

export interface SrcSet {
  url: string;
  descriptor: string;
}

export interface Sizes {
  media: string;
  size: string;
}

/*
 * pass in a url to see if it's a valid mediaserver url
 * if yes, that means ops can be applied
 * if no, means it's from some 3rd party resource and should not get ops or go through mediaserver
 */
export function canHaveOps(url: string) {
  const regex = new RegExp("/(i(-stg)?.iheart.com|iscale.iheart.com|mediaserver..*)/");
  return regex.test(url) && !url.includes("ops=");
}

export function getContestMediaServerUrl(host: string, url: string) {
  const encodedUrl =
    typeof btoa !== "undefined" ? btoa(utf8.encode(url)) : Buffer.from(utf8.encode(url)).toString("base64");
  return `https://${host}/v3/url/${encodedUrl}`;
}

export const getResponsiveValues = (imgUrl: string, layout = "thumb-top") => {
  const quality = 65;
  let srcset: SrcSet[] = [];
  let sizes: Sizes[] = [];
  let placeholderSrcset: SrcSet[] = [];
  let dimensions = { width: 288, height: 162 };

  if (canHaveOps(imgUrl)) {
    switch (layout) {
      case "square":
        srcset = PodcastSrcSet(imgUrl);
        sizes = PodcastSizes();
        dimensions = { width: 132, height: 132 };
        placeholderSrcset = PodcastSrcSet(placeholderImage);
        break;
      case "thumb-right":
      case "thumb-left":
        srcset = [
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(330,186),quality(${quality})`,
            descriptor: "330w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(420,236),quality(${quality})`,
            descriptor: "420w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(510,287),quality(${quality})`,
            descriptor: "510w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(600,338),quality(${quality})`,
            descriptor: "600w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(690,388),quality(${quality})`,
            descriptor: "690w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(740,416),quality(${quality})`,
            descriptor: "740w",
          },
        ];
        sizes = [
          {
            media: "(max-width: 360px)",
            size: "330px",
          },
          {
            media: "(max-width: 450px)",
            size: "420px",
          },
          {
            media: "(max-width: 540px)",
            size: "510px",
          },
          {
            media: "(max-width: 630px)",
            size: "600px",
          },
          {
            media: "(max-width: 720px)",
            size: "690px",
          },
          {
            media: "(max-width: 767px)",
            size: "740px",
          },
          {
            media: "(min-width: 768px) and (max-width: 800px)",
            size: "420px",
          },
          {
            media: "(max-width: 940px)",
            size: "510px",
          },
          {
            media: "(max-width: 1230px)",
            size: "600px",
          },
          {
            media: "(max-width: 1379px)",
            size: "690px",
          },
          {
            media: "(min-width: 1380px)",
            size: "740px",
          },
        ];
        placeholderSrcset = [
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(330,186),quality(${quality})`,
            descriptor: "330w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(420,236),quality(${quality})`,
            descriptor: "420w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(510,287),quality(${quality})`,
            descriptor: "510w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(600,338),quality(${quality})`,
            descriptor: "600w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(690,388),quality(${quality})`,
            descriptor: "690w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(740,416),quality(${quality})`,
            descriptor: "740w",
          },
        ];
        break;
      case "thumb-top":
      default:
        srcset = [
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(330,186),quality(${quality})`,
            descriptor: "330w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(420,236),quality(${quality})`,
            descriptor: "420w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(510,287),quality(${quality})`,
            descriptor: "510w",
          },
          {
            url: `${imgUrl}?ops=gravity(%22north%22),fit(620,349),quality(${quality})`,
            descriptor: "620w",
          },
        ];
        sizes = [
          {
            media: "(max-width: 360px)",
            size: "330px",
          },
          {
            media: "(max-width: 450px)",
            size: "420px",
          },
          {
            media: "(max-width: 540px)",
            size: "510px",
          },
          {
            media: "(max-width: 659px)",
            size: "620px",
          },
          {
            media: "(min-width: 660px) and (max-width: 719px)",
            size: "330px",
          },
          {
            media: "(max-width: 890px)",
            size: "420px",
          },
          {
            media: "(max-width: 970px)",
            size: "510px",
          },
          {
            media: "(max-width: 1159px)",
            size: "330px",
          },
          {
            media: "(min-width: 1160px)",
            size: "420px",
          },
        ];
        placeholderSrcset = [
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(330,186),quality(${quality})`,
            descriptor: "330w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(420,236),quality(${quality})`,
            descriptor: "420w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(510,287),quality(${quality})`,
            descriptor: "510w",
          },
          {
            url: `${placeholderImage}?ops=gravity(%22north%22),fit(620,349),quality(${quality})`,
            descriptor: "620w",
          },
        ];
        break;
    }
  }

  return {
    srcset,
    sizes,
    dimensions,
    placeholderSrcset,
  };
};

export const generateImageAssetSizes = (imgUrl: string) => {
  const srcset: SrcSet[] = [
    {
      url: `${imgUrl}?ops=max(360,0),quality(70)`,
      descriptor: "360w",
    },
    {
      url: `${imgUrl}?ops=max(480,0),quality(80)`,
      descriptor: "480w",
    },
    {
      url: `${imgUrl}?ops=max(650,0),quality(80)`,
      descriptor: "650w",
    },
    {
      url: `${imgUrl}?ops=max(1060,0),quality(80)`,
      descriptor: "940w",
    },
  ];
  const sizes: Sizes[] = [
    {
      media: "(max-width: 361px)",
      size: "360px",
    },
    {
      media: "(max-width: 481px)",
      size: "480px",
    },
    {
      media: "(max-width: 651px)",
      size: "650px",
    },
    {
      media: "(min-width: 1060px)",
      size: "940px",
    },
    {
      media: "auto",
      size: "",
    },
  ];
  const placeholderSrcset: SrcSet[] = [
    {
      url: `${placeholderImage}?ops=max(360,0),quality(70)`,
      descriptor: "360w",
    },
    {
      url: `${placeholderImage}?ops=max(480,0),quality(80)`,
      descriptor: "480w",
    },
    {
      url: `${placeholderImage}?ops=max(650,0),quality(80)`,
      descriptor: "650w",
    },
    {
      url: `${placeholderImage}?ops=max(1060,0),quality(80)`,
      descriptor: "940w",
    },
  ];

  return { srcset, sizes, placeholderSrcset };
};

export const PodcastSrcSet = (imgUri: string) => [
  {
    url: `${imgUri}?ops=max(150,150),quality(70)`,
    descriptor: "360w",
  },
  {
    url: `${imgUri}?ops=max(270,270),quality(70)`,
    descriptor: "600w",
  },
  {
    url: `${imgUri}?ops=max(380,380),quality(70)`,
    descriptor: "800w",
  },
  {
    url: `${imgUri}?ops=max(480,480),quality(70)`,
    descriptor: "1060w",
  },
  {
    url: `${imgUri}?ops=max(250,250),quality(70)`,
    descriptor: "1250w",
  },
  {
    url: `${imgUri}?ops=max(300,300),quality(70)`,
    descriptor: "1251w",
  },
];

export const PodcastSizes = () => [
  {
    media: "(max-width: 359px)",
    size: "360px",
  },
  {
    media: "(max-width: 599px)",
    size: "600px",
  },
  {
    media: "(max-width: 759px)",
    size: "800px",
  },
  {
    media: "(max-width: 1059px)",
    size: "1060px",
  },
  {
    media: "(max-width: 1249px)",
    size: "1250px",
  },
  {
    media: "any",
    size: "1251px",
  },
];

export function replaceImgWithAmpImg(html: string) {
  return html.replace(/<img(?:.|\n)*?>/g, match => {
    const [, height] = /height[:|=][\s|"]?(.*?x{1})/.exec(match) || [null, "225"];
    const [, width] = /width[:|=][\s|"]?(.*?x{1})/.exec(match) || [null, "400"];
    const [, alt] = /alt="(.*?)"/.exec(match) || [null, "Alt Text"];
    const [, src] = /src="(.*?)"/.exec(match) || [null, placeholderImage];

    if (!src) {
      return "";
    }

    return `
            <amp-img
                alt="${alt}"
                src="${src}"
                width="${width}"
                height="${height}"
                layout="responsive"
            >
                <noscript>
                    ${match}
                </noscript>
            </amp-img>
        `;
  });
}
